<template>
    <div>
        <div v-if="$apollo.loading">
            <b-row
                class="mt-2 d-flex align-items-center justify-content-center"
            >
                <b-col class="d-flex align-items-center justify-content-center">
                    <Transition>
                        <h1><i class="fas fa-spinner fa-spin ml-1"></i></h1>
                    </Transition>
                </b-col>
            </b-row>
        </div>
        <div v-else-if="invite.id">
            <div class="header pb-6 d-flex align-items-center">
                <!-- Mask -->
                <span class="mask bg-white opacity-8"></span>
                <!-- Header container -->
                <b-container fluid class="d-flex align-items-center">
                    <b-row>
                        <b-col lg="12"> </b-col>
                    </b-row>
                </b-container>
            </div>
            <b-container fluid class="mt-1">
                <!-- <b-row>
                    <b-col class="mt-4 mb-2">
                        <div
                            @click="$router.back()"
                            class="icon icon-shape rounded-circle shadow bg-primary text-white"
                        >
                            <i class="fa fa-arrow-left"></i>
                        </div>
                    </b-col>
                </b-row> -->
                <b-row>
                    <b-col sm="12">
                        <div>
                            <b-card>
                                <b-row>
                                    <b-col>
                                        <h1>
                                            Mentor invite: {{ hackathon.name }}
                                        </h1>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <h4>
                                            Great news! You have been invited to
                                            be a mentor for the "{{
                                                hackathon.name
                                            }}" hackathon.
                                        </h4>
                                        <p>
                                            As a hackathon mentor, your primary
                                            role is to engage with teams during
                                            the hackathon and share your
                                            perspective and insights.
                                        </p>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col>
                                        <base-button
                                            type="primary"
                                            :pill="true"
                                            native-="submit"
                                            class=""
                                            @click="accept_invitation()"
                                            :loading="
                                                accept_invite_button.loading
                                            "
                                            :disabled="
                                                accept_invite_button.loading
                                            "
                                            :success="
                                                accept_invite_button.success
                                            "
                                            >Become a mentor
                                        </base-button>
                                        <!-- <base-button
                                            type="danger"
                                            :pill="true"
                                            class=""
                                            @click="route_to('/login')"
                                            >Decline
                                        </base-button> -->
                                    </b-col>
                                </b-row>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <div v-else>
            <div class="header pb-6 d-flex align-items-center">
                <!-- Mask -->
                <span class="mask bg-white opacity-8"></span>
                <!-- Header container -->
                <b-container fluid class="d-flex align-items-center">
                    <b-row>
                        <b-col lg="12"> </b-col>
                    </b-row>
                </b-container>
            </div>
            <b-container fluid class="mt-1">
                <!-- <b-row>
                    <b-col class="mt-4 mb-2">
                        <div
                            @click="$router.back()"
                            class="icon icon-shape rounded-circle shadow bg-primary text-white"
                        >
                            <i class="fa fa-arrow-left"></i>
                        </div>
                    </b-col>
                </b-row> -->
                <b-row>
                    <b-col sm="12">
                        <div>
                            <b-card>
                                <b-row>
                                    <b-col>
                                        <h1>
                                            This invite is no longer available.
                                            <i
                                                class="fas fa-spinner fa-spin ml-1"
                                            ></i>
                                        </h1>
                                    </b-col>
                                </b-row>
                            </b-card>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
    </div>
</template>

<script>
import {
    GET_HACKATHON_PROFILE_FROM_USER_ID,
    GET_HACKATHON_MENTOR_INVITE,
} from "@/graphql/queries";
import { UPDATE_HACKATHON_EVENT_TEAM_MENTOR_INVITE } from "@/graphql/mutations";
export default {
    name: "HackathonMentorInvite",
    components: {},
    apollo: {
        get_hackathon_event_team_invite: {
            query: GET_HACKATHON_MENTOR_INVITE,
            result(res) {
                let invites = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(
                        res.data.allHackathonEventTeamMentorInvite
                    )
                );
                if (invites.length < 1) {
                    this.invite = {
                        id: null,
                        uid: null,
                    };
                    setTimeout(() => {
                        this.$router.push({ path: "/" });
                    }, 1500);
                    return;
                }
                this.invite = {
                    id: invites[0].id,
                    uid: invites[0].uid,
                };
                this.hackathon = {
                    id: invites[0].hackathon__id,
                    name: invites[0].hackathon__name,
                    organizer: invites[0].hackathon__creator__email,
                };
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    invite_uid: this.invite_uid,
                };
            },
            update(data) {
                this.apollo_data.get_hackathon_event_team_invite = data;
            },
            skip: true,
        },
        get_hakcathon_profile: {
            query: GET_HACKATHON_PROFILE_FROM_USER_ID,
            result(res) {
                let profiles = graph_utils.flatten_objects_recursive(
                    graph_utils.apollo_to_obj_recursive(
                        res.data.allHackathonProfileProfile
                    )
                );
                if (profiles.length > 0) {
                    this.hackathon_profile.id = profiles[0].id;
                }
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    user: store.getters.getUser.id_b64,
                };
            },
            update(data) {
                this.apollo_data.get_hakcathon_profile = data;
            },
        },
    },
    data() {
        return {
            apollo_data: {
                get_hackathon_event_team_invite: null,
                get_hakcathon_profile: null,
            },
            invite: { id: null, uid: null },
            hackathon: {
                name: null,
            },
            hackathon_profile: {
                id: null,
            },
            invite_uid: null,
            accept_invite_button: {
                loading: false,
                success: false,
            },
        };
    },
    methods: {
        get_invite_uid() {
            let invite_uid = this.$route.query.uid;

            if (invite_uid) {
                this.invite_uid = invite_uid;
                if (
                    !this.$apollo.queries.get_hackathon_event_team_invite.skip
                ) {
                    this.$apollo.queries.get_hackathon_event_team_invite.refetch();
                }
                graph_utils.enable_query(
                    this.$apollo.queries.get_hackathon_event_team_invite
                );
            } else {
                graph_utils.disable_query(
                    this.$apollo.queries.get_hackathon_event_team_invite
                );
                this.$router.push({
                    path: "/",
                });
            }
        },
        accept_invitation() {
            this.accept_invite_button.loading = true;
            this.$apollo
                .mutate({
                    mutation: UPDATE_HACKATHON_EVENT_TEAM_MENTOR_INVITE,
                    variables: {
                        invite_id: this.invite.id,
                        status: "ACCEPTED",
                        hackathon_profile_id: this.hackathon_profile.id,
                    },
                })
                .then(() => {
                    global_event_emitter.$emit(
                        "hackathon_sidebar_reload_required"
                    );
                    this.accept_invite_button.loading = false;
                    this.accept_invite_button.success = true;
                    this.$notify({
                        message: `You are now a mentor for the '${this.hackathon.name}' Hackathon`,
                        timeout: 3000,
                        icon: "ni ni-check-bold",
                        type: "success",
                    });
                    setTimeout(() => {
                        this.accept_invite_button.success = false;
                    }, 1000);

                    setTimeout(() => {
                        this.$router.push({
                            name: "HackathonView",
                            params: {
                                hackathon_id: this.hackathon.id,
                            },
                        });
                    }, 1000);
                })
                .catch((err) => {
                    console.log(err);
                    this.accept_invite_button.loading = false;
                });
        },
    },
    mounted() {
        this.get_invite_uid();
    },
    watch: {
        "$route.query.uid"() {
            this.get_invite_uid();
        },
    },
};
</script>

<style></style>
